import menuJSON from 'data/menu.json';
import { IMenuItem } from 'types';
// Types images
import RecommendedImg from 'assets/recommended.png';
import AppetizerImg from 'assets/appetizer.png';
import PastaImg from 'assets/pasta.png';
import FishImg from 'assets/fish.png';
import MeatImg from 'assets/meat.png';
import SaladaImg from 'assets/salad.png';

const menuData: {[properties: string]: IMenuItem[]} = menuJSON;

const typeToImageMapping: {[properties: string]: any} = {
    "Raccomandati": RecommendedImg,
    "Antipasti": AppetizerImg,
    "Primi Piatti":  PastaImg,
    "Secondi di Pesce": FishImg,
    "Secondi di Carne": MeatImg,
    "Contorni": SaladaImg 
}

const Menu = () => {
    return (
        <>
            {Object.keys(menuData).map((foodType: string, key: number) => {
                const img = typeToImageMapping.hasOwnProperty(foodType) ? typeToImageMapping[foodType] : <></>
                return (
                    <div className="flex flex-col justify-center items-center" key={`food-type-${key}`}>
                        <h2 className="text-4xl font-Karla font-semibold my-8">
                            {foodType}
                        </h2>
                        <img className="w-32 h-32" src={img} alt={foodType}/>
                        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
                        {menuData[foodType].map((foodItem: IMenuItem, key: number) => {
                            return (
                                <div className="flex flex-col justify-center border-b-2 border-neutral-200  pb-4 mt-8 text-center">
                                    <h3 className="text-2xl uppercase font-Karla font-semibold">
                                        {foodItem.name}
                                    </h3>
                                    {foodItem.subtitle && (
                                        <h3 className="text-xl uppercase font-Karla">
                                            ({foodItem.subtitle})
                                        </h3>
                                    )}
                                    {foodItem.ingredients && (
                                        <p className="text-lg pt-3">
                                            {foodItem.ingredients}
                                        </p>
                                    )}
                                    <h4 className="text-xl font-Karla">
                                        {foodItem.price}
                                    </h4>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                )
            })}

            <p className="mt-16 text-neutral-600 text-center">
                Alcuni prodotti potrebbero essere surgelati
            </p>
        </>
    )
}

export default Menu;