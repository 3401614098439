import { Home, Menu, PrivacyPolicy, NoMatch } from 'pages';
import type { RouteObject } from "react-router-dom";

const routes: RouteObject[] = [
    {
      path: "/",
      children: [
        { index: true, element: <Home /> },
        { path: "/menu", element: <Menu/> },
        { path: "/privacy-policy", element: <PrivacyPolicy/> },
        { path: "*", element: <NoMatch /> }
      ]
    }
];

export default routes;