import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from 'assets/logo.png';
import { SiHomeassistantcommunitystore } from 'react-icons/si';
import { BiFoodMenu } from 'react-icons/bi';
import { INavLink } from 'types';

const navLinks: INavLink[] = [
    {
        link: '/',
        innerTxt: 'Home',
        icon: <SiHomeassistantcommunitystore size={24}/>
    },
    {
        link: '/menu',
        innerTxt: 'Menu',
        icon: <BiFoodMenu size={24}/>
    },
]

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        setIsMobileMenuOpen(false);
        document.body.classList.remove('modal-open');
    }, [location.pathname])

    const toggleMobileMenuStatus = () => {
        const DOMFunction = isMobileMenuOpen ? 'remove' : 'add';
        document.body.classList[DOMFunction]('modal-open');
        setIsMobileMenuOpen(!isMobileMenuOpen);
    }

    return (
        <>
            <header className="w-4/5 md:w-full container mx-auto py-6 font-Karla">
                <div className="select-none flex justify-center align-center items-center gap-4">
                    <Link to={`/`} className='no-underline text-slate-600 hover:text-slate-900'>
                        <img src={Logo} className="inline w-24 md:w-{30px}" alt="La Neviera Logo" />
                    </Link>
                    <h1 className="text-3xl md:text-4xl text-center mt-6 font-Coustard">
                        La Neviera
                    </h1>
                   

                </div>

                {/* Desktop menu */}
                <ul className="hidden md:flex justify-center gap-8 mt-12 text-xl font-semibold">
                    {navLinks.map((navlink: INavLink, key: number) => {
                            const isActive = location.pathname === navlink.link ? true : false;
                            return (
                                <Link to={navlink.link} className={`pb-2 ${isActive ? "font-bold border-b-4 border-red-700" : "font-normal hover:border-b-4 hover:border-red-700"}`} key={key}>
                                    <li className="flex items-center gap-2 justify-center md:justify-start" >
                                        {navlink.icon}
                                        <span className="hidden md:block">
                                            {navlink.innerTxt}
                                        </span>
                                    </li>
                                </Link>
                            )}
                        )}
                </ul>

                {/* Mobile Menu Button */}
                <div className="md:hidden flex items-center absolute top-5 right-5 z-50">
                    <button className={`w-10 h-10 relative focus:outline-none text-gray-500 ${isMobileMenuOpen ? 'rounded-full bg-neutral-200' : ''}`} onClick={toggleMobileMenuStatus}>
                        <div className="block w-5 absolute left-1/2 top-1/2 transform  -translate-x-1/2 -translate-y-1/2 ">
                            <span aria-hidden="true" className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${isMobileMenuOpen ? 'rotate-45' : '-translate-y-1.5' }`}></span>
                            <span aria-hidden="true" className={`block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out" ${isMobileMenuOpen ? 'opacity-0' : '' }`}></span>
                            <span aria-hidden="true" className={`block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out" ${isMobileMenuOpen ? '-rotate-45' : 'translate-y-1.5' }`}></span>
                        </div>
                    </button>
                </div>

                {/* Mobile Menu */}
                <div className={`md:hidden absolute inset-0 top-0 overflow-hidden ${isMobileMenuOpen ? '' : 'hidden'}`}>
                    <div className="py-3 bg-white text-center animate-animated animate-slideInDown h-full">
                        <ul className="justify-center gap-8 text-xl font-semibold">
                        {navLinks.map((navlink: INavLink, key: number) => {
                                const isActive = location.pathname === navlink.link ? true : false;
                                return (
                                    <Link to={navlink.link} className={`pb-2 ${isActive ? "font-bold" : "font-normal"}`} key={key}>
                                        <li className="flex items-center gap-x-4 mt-8 justify-center" >
                                            {navlink.icon}
                                            <span className="block">
                                                {navlink.innerTxt}
                                            </span>
                                        </li>
                                    </Link>
                                )}
                            )}
                        </ul>
                        <div className="bg-neutral-800 absolute bottom-0 w-full py-4">
                            <p className="text-sm sm:ml-4 sm:pl-4 sm:py-2 sm:mt-0 mt-4 text-white">
                                © 2022 — Made with ❤️ by
                                <a href="https://thelicato.io" className="ml-1 underline" rel="noopener noreferrer" target="_blank">Angelo Delicato</a> - 
                                P.IVA 03628980611
                            </p>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default Header;