const Home = () => {
    return (
        <>
            <div>
                <p>
                    Il Ristorante La Neviera è immerso nella natura del Parco Regionale Foce Garigliano, nel quale potete trascorrere ore di relax nel modo più tranquillo possibile. La nostra cucina è ricca di piatti tipici ma anche di gustosi piatti a base di pesce. Tra le specialità puoi trovare anche la carne alla griglia grazie alla nostra brace a vista con arco.
                </p>
            </div>
        </>
    )
}

export default Home;