
import { Link } from 'react-router-dom';

const NoMatch = () => {
    return (
        <>
            <div className="h-full w-full">
                <div className="text-center flex flex-col justify-center">
                    <div>
                        <h5 className="text-neutral-600 font-bold">Errore 404</h5>
                        <h3 className="text-5xl font-bold py-4">Pagina non trovata</h3>
                        <h4>Controlla l'URL nella barra degli indirizzi e prova di nuovo.</h4>
                        <Link to={"/"}>
                            <button className="text-white rounded bg-zinc-700 p-3 mt-4 hover:bg-zinc-800">Ritorna alla Homepage</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoMatch;